<script lang="ts" setup>
import type { HTMLInputProps } from "../types-utils";
const isOpen = ref(false);
const modifierList = ["wide"];

withDefaults(
	defineProps<
		{
			modelValue?: string;
			modifiers?: Array<(typeof modifierList)[number]>;
			placeholder?: string;
			savePlaceholder?: boolean;
		} & HTMLInputProps
	>(),
	{ modelValue: "" }
);

const emit = defineEmits<{
	(event: "update:modelValue", value: string): void;
}>();
const handleClick = (event: Event) => {
	event.preventDefault();
	isOpen.value = !isOpen.value;
};
const handleBlur = () => {
	isOpen.value = false;
};

const hide = () => {
	isOpen.value = false;
};

const setValue = (newValue: string) => {
	hide();
	emit("update:modelValue", newValue);
};
</script>

<template>
	<div class="wrapper" tabindex="-1" @blur="handleBlur">
		<APopper :show="isOpen" :class="modifiers" disable-click-away offset-distance="4">
			<AInputGroup class="dropdown" @click="handleClick">
				<div class="before-input">
					<slot name="beforeInput" />
				</div>
				<AText v-if="savePlaceholder" :class="['placeholder', { moved: modelValue }]">{{ placeholder }}</AText>
				<AInput
					readonly
					:placeholder="savePlaceholder ? '' : placeholder"
					:value="modelValue"
					:aria-label="placeholder ?? 'Select'"
				/>
				<i class="icon-arrow" :class="{ caret: true, 'arrow-up': isOpen }" />
			</AInputGroup>
			<template #content><slot name="content" :hide="hide" :setValue="setValue" /></template>
		</APopper>
	</div>
</template>
<style scoped lang="scss">
.wrapper {
	position: relative;

	:deep(.popper) {
		width: 100%;
		background-color: var(--a-input-group-background);
		border-radius: var(--a-input-group-border-radius);
		padding: 0;
		max-height: 250px;
		overflow: auto;
	}
}
.dropdown {
	cursor: pointer;
	input {
		cursor: pointer;
		pointer-events: none;
		outline: none;
		border: none;
	}
}
.before-input {
	font-size: 16px;
	color: var(--conakry);
	padding-left: 10px;
}

.icon-arrow {
	display: inline-flex;
	width: 16px;
	height: 16px;
	align-items: center;
	justify-content: center;
	padding: 0;
	flex-shrink: 0;
	margin-right: 10px;

	&::before {
		content: "";
		display: inline-flex;
		flex-shrink: 0;
		width: 8px;
		height: 8px;
		border-bottom: 1px solid var(--conakry);
		border-left: 1px solid var(--conakry);
		transform: translateY(-2px) rotate(-45deg);
		transition: 0.3s;
	}

	&.arrow-up::before {
		transform: translateY(3px) rotate(135deg);
	}
}
.wide {
	width: 100%;
}

.placeholder {
	position: absolute;
	pointer-events: none;
	padding: var(--a-input-padding);
	top: 0;
	left: 10px;
	font-size: var(--a-input-font-size);
	color: var(--a-input-placeholder-color);
	line-height: var(--a-input-line-height);
	transition: 0.3s;

	&.moved {
		font-size: 12px;
		transform: translateY(-10px);
	}

	&.moved + input {
		transform: translateY(8px);
	}
}
</style>
